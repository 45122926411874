<template>
    <select class="form-control" id="select2ajaxcustomer-template" style="width: 100%">
    </select>
</template>
<script>

import $ from "jquery";
export default {
  
  name: 'select-2-ajax-customer',
  data() {
    return {
      bInitialFlag : false
    }
  },
  props: ['options', 'value', 'store_id'],
  template: '#select2ajaxcustomer-template',
  methods: {
    initialValue: function(id,name){
      this.bInitialFlag = true;
      var newOption = new Option(name, id);
      $(this.$el).append(newOption).trigger('change');
    },
    setValue(id, name) {
      const newOption = new Option(name, id, true, true); // Select the new option by default
      $(this.$el)
        .empty() // Clear previous options
        .append(newOption) // Add the new option
        .trigger("change"); // Notify Select2 of the change
    },
  },
  mounted: function() {
    let vm = this;
    $(this.$el)
      // init select2
      .select2({
        ajax: {
          url: this.HttpServices.generate_url('/ab_customer/lookup/'),
          headers : this.HttpServices.createHeader(),
          dataType: 'json',
          delay: 500,
          cache: true,
          data: function(params) {
            var query = {
              store_id : vm.store_id,
              search: params.term,
              _type: 'query',
              q: params.term
            };
            return query;
          },
          processResults: function(data) {
            var result_data = $.map(data.data, function(obj) {
              obj.text = obj.text;
              return obj;
            });
            return {
              results: result_data
            };
          }
        },
        placeholder: 'โปรดพิมพ์อย่างน้อย 1 ตัวอักษร',
        minimumInputLength: 1,
        templateResult: function(item) {
          if (item.id) {return item.id + ' : ' + item.text;}
          return item.text;
        },
        templateSelection: function(item) {
          if (item.id) { return item.id + ' : ' + item.text;}
          return item.text;
        }
      })
      .val(this.value)
      .on('change', function() {
        if(vm.bInitialFlag){
          vm.bInitialFlag = false;
        }else{
          vm.$emit('change', this.value);
        }
        
      });
  },
  watch: {
    value: function(value) {
      // update value
      $(this.$el).val(value);
    },
    options: function(options) {
      // update options
      $(this.$el)
        .empty()
        .select2({ data: options });
    }
  },
  destroyed: function() {
    $(this.$el)
      .off()
      .select2('destroy');
  }
};
</script>

<style>
</style>
