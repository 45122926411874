<template>
  <div>
    <b-modal id="dialog_extravalue" size="xl">
      <template #modal-title>
        <h3>ข้อมูลเพิ่มเติม</h3>
      </template>
      <div>
        <div>
          <b-tabs content-class="mt-3">
            <b-tab title="ข้อมูลสินค้า">
             <div>
                <div class="mb-2 row">
                  <label class="col-sm-4 col-form-label label_algin_right">ต้นทุนการจัดส่ง</label>
                  <div class="col-sm-8">
                    <base-input name="cost_shipping" v-model="item.cost_shipping" placeholder="ต้นทุนราคาสินค้า"  v-decimal-format>
                    </base-input>
                  </div>
                </div>
                <div class="mb-2 row">
                  <label class="col-sm-4 col-form-label label_algin_right">ราคาเริ่มต้น</label>
                  <div class="col-sm-8">
                    <base-input name="upsales_start" placeholder="ต้นทุนราคาสินค้า"  v-decimal-format>
                    </base-input>
                  </div>
                </div>
                <div class="mb-2 row">
                  <label class="col-sm-4 col-form-label label_algin_right">ราคาสุดท้าย</label>
                  <div class="col-sm-8">
                    <base-input name="upsales_end" placeholder="ต้นทุนราคาสินค้า"  v-decimal-format>
                    </base-input>
                  </div>
                </div>
                <div class="mb-2 row">
                <label class="col-sm-4 col-form-label label_algin_right">หมายเหตุ</label>
                  <div class="col-sm-8">
                    <b-form-textarea name="upsales_remark" placeholder="รายละเอียดเพิ่มเติม"  rows="3" max-rows="5"></b-form-textarea>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
        
       
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="save()" class="style_btn"><i class="fa fa-check"></i> ตกลง</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
  import $ from 'jquery';
  export default {
    name: 'order-extravalue',
    data() {
      return {
        
      }
    },
    props: ['item'],
    methods: {
      close () {
        this.$bvModal.hide(`dialog_extravalue`);
      },
       save () {
        this.$bvModal.hide(`dialog_extravalue`);
      }
    },
    async mounted() {
      $(document).ready(function () {
      
      });
    }
  }
</script>
<style scope>
  .checkbox_style > .custom-control {
    left: 12px;
  }
  .carousel-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 10;
    padding: 20px;
    color: #000000;
    text-align: left;
    text-shadow: initial;
  }
  .carousel-indicators li {
    background-color: #dddd;
  }
  .carousel-indicators {
    bottom: -35px;
  }
  strong {
    font-weight: bold;
  }
</style>
