<template>
    <select class="form-control" :id="id" style="width: 100%"></select>
</template>
<script>

import $ from "jquery";
export default {
  
  name: 'select-2-ajax-location',
  data() {
    return {
      bInitialFlag : false
    }
  },
  props: ['options', 'value', 'location_id', 'id'],
  // template: '#select2ajaxlocation-template',
  methods: {
    initialValue: function(id,name,city){
      this.bInitialFlag = true;
      var text =  `<span>${name} <span class="badge badge-pill badge-success">${city}</span></span>`;
      var newOption = new Option(text, id);
      $(this.$el).append(newOption).trigger('change');
    },
    clearSelection() {
    $(this.$el).val(null).trigger('change');
    console.log('select2 selection cleared');
  },
  },
  mounted: function() {
    let vm = this;
    $(this.$el)
      // init select2
      .select2({
        ajax: {
          url: this.HttpServices.generate_url('/ab_location/lookup/'),
          headers : this.HttpServices.createHeader(),
          dataType: 'json',
          delay: 500,
          cache: true,
          data: function(params) {
            var query = {
              // location_id : vm.location_id,
              search: params.term,
              _type: 'query',
              q: params.term
            };
            return query;
          },
          processResults: function(data) {
            var result_data = $.map(data.data, function(obj) {
              obj.text = obj.text;
              return obj;
            });
            return {
              results: result_data
            };
          }
        },
        placeholder: 'โปรดพิมพ์อย่างน้อย 2 ตัวอักษร',
        minimumInputLength: 2,
        templateResult: function(item) {
          if (!item.id) {
            return item.text;
          }
          return $(`<span>${item.text} <span class="badge badge-pill badge-success">${item.city}</span><span class="badge badge-pill badge-warning">${item.district}</span><span class="badge badge-pill badge-info">${item.subdistrict}</span></span>`);
        },
        templateSelection: function(item) {
          if (!item.id) {
            return item.text;
          }
          if(typeof item.city != "undefined"){
           return $(`<span>${item.text} <span class="badge badge-pill badge-success">${item.city}</span><span class="badge badge-pill badge-warning">${item.district}</span><span class="badge badge-pill badge-info">${item.subdistrict}</span></span>`);
          }else{
           return $(`${item.text}`);
          }
        }
      })
      .val(this.value)
      .on('change', function() {
        if(vm.bInitialFlag){
          vm.bInitialFlag = false;
        }else{
          vm.$emit('change', this.value);
        }
        
      });
  },
  watch: {
    value: function(value) {
      // update value
      $(this.$el).val(value);
    },
    options: function(options) {
      // update options
      $(this.$el)
        .empty()
        .select2({ data: options });
    }
  },
  destroyed: function() {
    $(this.$el)
      .off()
      .select2('destroy');
  }
};
</script>

<style>
</style>
