<template>
  <div>
    <b-modal id="dialog_order_detail_product" size="lg">
      <template #modal-title>
        <h3><i class="fa fa-edit"></i> ข้อมูลรายละเอียดเพิ่มเติม</h3>
      </template>
      <div>
        <div class="mb-4 row">
          <label class="col-sm-4 col-form-label label_algin_right">ข้อความ 1</label>
          <div class="col-sm-8">
            <b-form-textarea name="msg_fr" placeholder="ข้อความ 1" v-model="item.msg_fr"></b-form-textarea>
          </div>
        </div>
        <div class="mb-4 row">
          <label class="col-sm-4 col-form-label label_algin_right">ข้อความ 2</label>
          <div class="col-sm-8">
            <b-form-textarea name="msg_se" placeholder="ข้อความ 2" v-model="item.msg_se"></b-form-textarea>
          </div>
        </div>
        <div class="mb-4 row">
          <label class="col-sm-4 col-form-label label_algin_right">ชื่อผู้รับ(ดอกไม้)/ชื่อผู้เสียชีวิต(พวงหรีด)</label>
          <div class="col-sm-8">
            <base-input list="recipient_list" name="recipient" placeholder="ชื่อผู้รับ(ดอกไม้)/ชื่อผู้เสียชีวิต(พวกหรีด)" v-model="item.recipient"></base-input>
          </div>
        </div>
        <div class="mb-4 row">
          <label class="col-sm-4 col-form-label label_algin_right">อัพโหลดโลโก้ <br><span class="text-xs text-danger">(กดที่รูปภาพเพื่อเปลี่ยนรูป)</span></label>
          <div class="col-sm-8">
            <div class="card">
              <div class="card-body m-auto">
                <input  type="file" id="fileUploadLogo" accept="image/*" style="display: none" @change="uploadFileChangeHandlerLogo"/>
                <img :src="displayLogoImg?displayLogoImg:noImage" class="img-thumbnail" style="max-width: 180px;" @click="uploadFileHandlerLogo()"/>
                
              </div>
              <a v-if="displayLogoImg"  class="btn btn-warning" @click="removeImageA">ลบ</a>
            </div>
          </div>
        </div>
        <div class="mb-4 row">
          <label class="col-sm-4 col-form-label label_algin_right">อัพโหลดรูป <br><span class="text-xs text-danger">(กดที่รูปภาพเพื่อเปลี่ยนรูป)</span>
          </label>
          
          <div class="col-sm-8">
            <div class="card">
              <div class="card-body m-auto">
                <input  type="file" id="fileUpload" accept="image/*" style="display: none" @change="uploadFileChangeHandler" multiple/>
                <span class="thumbnail" v-for="(img, index) in item.imageDisplays" :key="`detail_display_image_${index}`">
                  <div class="action-delete"><a href="javascript:void(0)" @click="deleteImageHandler(img, index)"><i class="fa fa-times"></i></a></div>
                  <img :src="img?`${img}`:noImage"
                      class="img-thumbnail" style="max-width: 150px;" @click="uploadFileHandler()">
                </span>
                <img v-if="item.imageDisplays.length === 0" :src="noImage" class="img-thumbnail" style="max-width: 180px;" @click="uploadFileHandler()">
              </div>
            </div>
          </div>
        </div>
        <div class="mb-4 row">
          <label class="col-sm-4 col-form-label label_algin_right">รายละเอียดเพิ่มเติม</label>
          <div class="col-sm-8">
            <b-form-textarea name="caption" placeholder="รายละเอียดเพิ่มเติม" v-model="item.caption" rows="3" max-rows="5"></b-form-textarea>
          </div>
        </div>
        <div class="mb-4 row">
          <label class="col-sm-4 col-form-label label_algin_right">สีป้ายพวงหรีด</label>
          <div class="col-sm-8">
            <select class="form-control" v-model="item.color">
              <option v-for="(data) in colors" :key="`list_order_color_select_${data.id}`" :value="data.id">
                {{data.name}}
              </option>
            </select>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="clickAgree()" class="style_btn"><i class="fa fa-check"></i> ตกลง</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import $ from 'jquery';
import config from '../../../util/config';
import mock from '../mock/order';
import { funcs } from '../../../util/scripts';
import Constants from '../../../util/constants';
import Config from '../../../util/config';
export default {
  name: 'order-view-dialog-detail',
  data () {
    return {
      noImage: Constants.noImageData,
      displayImgs: [],
      file: '',
      apiUrl: config.apiUrl,
      displayLogoImg: '',
      fileLogo: '',
      colors: [],
      mock: mock,
      funcs: funcs,
      imgs: [],
      item: {
        logoImg: '',
        logoFile: null,
        imageFiles: [],
        imageDisplays: []
      }
    }
  },
  props: ['detail'],
  methods: {
    productSelectedHandler(product){
      this.displayLogoImg = '';
      this.displayImgs = [];
      this.item = JSON.parse(JSON.stringify(product));
      this.displayLogoImg = this.item.logoImg;
    },

    uploadFileHandler(){
      $("#fileUpload").trigger("click");
    },
    removeImageA(){
      this.item.logoFile = "";
      this.displayLogoImg = null;
      this.fileLogo = null;
      this.item.logoImg = null
      this.file = null;
      this.removeLogoImage = true;
    },
    uploadFileChangeHandler (event) {
      let img = event.target.files
      if (img.length > 0) {
        const vm = this;
        vm.displayImgs = [];
        if(!vm.item.imageFiles){
          vm.item.imageFiles = [];
          vm.item.imageDisplays = [];
        }
        for (let i = 0; i < img.length; i++) {
          var reader = new FileReader();
          reader.onload = (event) => {
            vm.displayImgs.push(event.target.result);
            vm.item.imageDisplays.push(event.target.result);
          }
          reader.readAsDataURL(img[i]);
          vm.item.imageFiles.push(img[i]);
        }
        vm.imgs = img;
      } else {
        this.displayImgs = [];
        this.file = null;
        this.imgs = [];
      }
    },
    clickAgree () {
      console.log(this.item);
      this.$emit('selected', this.item);
      this.$bvModal.hide('dialog_order_detail_product');
    },
    close () {
      $('#fileUploadLogo').val('');
      $('#fileUpload').val('');
      this.$bvModal.hide('dialog_order_detail_product');
    },
    uploadFileHandlerLogo(){
      $("#fileUploadLogo").trigger("click");
    },
    async uploadFileChangeHandlerLogo (event) {
      let img = event.target.files;
      if (img.length > 0) {
        const vm = this;
        const reader = new FileReader();
        reader.onload = (e) => {
          vm.item.logoImg = e.target.result;
          vm.displayLogoImg = e.target.result;
        };
        reader.readAsDataURL(img[0]);

        this.item.logoFile = img[0];
      } else {
        this.displayLogoImg = null;
        this.fileLogo = null;
      }
    },
    getDataColor () {
      this.colors = this.mock.colors;
    },
    deleteImageHandler(img, index){
      let cnt = 0;
      const indexOf = img.indexOf(Config.apiUrl);
      if(indexOf>-1){
        if(this.item.imageDisplays&&this.item.imageDisplays.length>0){
          cnt = this.item.imageDisplays.length;
        }
        if(this.item.imageFiles&&this.item.imageFiles.length>0){
          this.item.imageFiles.splice(index-cnt, 1);
        }
      }
      this.item.imageDisplays.splice(index, 1);

    }
  },
  mounted() {
    this.getDataColor();
  }
}
</script>
<style scoped>
.thumbnail{position: relative;display: inline-block;}
.action-delete{
  position:absolute;
  right: 5px;
  top: 0px;
}
</style>
