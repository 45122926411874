<template>
  <b-modal size="lg" id="dialog_order_add_product">
    <template #modal-title>
      <h3>รายการสินค้า</h3>
    </template>

    <div>
      <div class="row">
        <div class="col-sm-6 mb-4">
          <b-form-checkbox
            v-if="criteria.subCat == '1'"
            :name="`checkbox_product_subcat`"
            v-model="criteria.subCatSelected"
            @change="searchHandler()"
            >ประเภทสินค้าหลัก</b-form-checkbox
          >
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <b-dropdown
            id="dropdown-1"
            text="แท็ก"
            class="dropdown_input_search ml-1 mb-1"
          >
            <b-dropdown-text style="margin-bottom: 0px;" text-class="mb-0">
              <ul style="list-style-type: none; padding-left: 0px;">
                <li
                  v-for="v in criteria.tag_filters"
                  :key="`filter_product_tags${v.id}`"
                  style="padding: .5rem 1rem; font-size: 0.875rem;"
                >
                  <input
                    type="checkbox"
                    class="checkbox_search_input"
                    :name="`checkbox_tag${v.id}`"
                    :id="`checkbox_tag${v.id}`"
                    v-model="v.selected"
                    @change="searchHandler()"
                  />
                  <label
                    class="form-check-label"
                    v-bind:for="`checkbox_tag${v.id}`"
                    >{{ v.name }}</label
                  >
                </li>
              </ul>
            </b-dropdown-text>
          </b-dropdown>
        </div>
        <div class="col-sm-6">
          <b-input-group>
            <b-input-group-append>
              <b-input-group-text class="input_border_radius_left_top_bt">
                <b-icon icon="search" />
              </b-input-group-text>
            </b-input-group-append>
            <b-form-input
              placeholder="ระบุการค้นหา เช่น ชื่อสินค้า"
              v-model="criteria.search"
              class="form-control-group"
              @keyup.enter.native="searchHandler()"
            >
            </b-form-input>
            <b-input-group-append>
              <b-button
                text="Button"
                variant="primary"
                @click="searchHandler()"
                style="width: 86px;"
              >
                ค้นหา
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
      <div class="table-responsive padding_top_20">
        <table class="table align-middle table-bordered">
          <thead>
            <tr class="text-center">
              <th style="min-width: 50px; width: 1px;">เลือก</th>
              <th style="min-width: 130px; width: 1px;">รหัสสินค้า</th>
              <th style="min-width: 50px; width: 1px;">รูป</th>
              <th>ชื่อสินค้า</th>
              <th>ประเภทสินค้า</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(data, index) in items"
              :key="`list_order_add_product_${index}`"
              :id="`checkbox_product${index}`"
            >
              <td class="text-center checkbox_style">
                <b-form-checkbox
                  @change="selectedProduct(...arguments, data, index)"
                  :name="`checkbox_product${index}`"
                  v-model="data.selected"
                />
              </td>
              <td>{{ data.code }}</td>
              <td>
                <img
                  :src="data.imgs_encode ? data.imgs_encode : data.imgs"
                  class="mx-auto d-block"
                  style="width: 30px; border-radius: 15px;"
                />
              </td>
              <td>{{ data.name }}</td>
              <td>{{ data.cat_name }}</td>
            </tr>
            <tr v-if="items.length === 0" class="text-center">
              <td colspan="5">ไม่มีข้อมูล</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="row padding_top_20">
        <div class="col-lg-2 col-md-2 col-3 page_m_auto">
          <select
            class="form-control form-control-sm"
            id="pageLimit"
            style="width: 80px;"
            v-model="paging.limit"
            v-on:change="changeLimit()"
          >
            <option v-for="data in paging.pages" :key="data" :value="data">
              {{ data }}
            </option>
          </select>
        </div>
        <div class="col-lg-10 col-md-12 col-sm-10">
          <div class="text-right paging_footer" v-if="paging.rowTotal > 0">
            <span
              class="display-total-paging"
              style="margin: auto; font-size: 12px;
                  font-family: Sarabun-Light;
                  margin-right: 12px;"
              >แสดงรายการที่
              <strong
                >{{ (paging.page - 1) * paging.limit + 1 }} ถึง
                {{ paging.page * paging.limit }}</strong
              >
              จาก
              <strong
                >{{ paging.rowTotal | numberIntFormat }} รายการ</strong
              ></span
            >
            <paginate
              v-model="paging.page"
              :first-last-button="true"
              :page-count="paging.pageTotal"
              :page-range="5"
              :margin-pages="2"
              :prev-text="'&lt;'"
              :next-text="'&gt;'"
              :first-button-text="'&verbar;&lt;'"
              :last-button-text="'&gt;&verbar;'"
              :click-handler="changePage"
              :container-class="
                'pagination pagination-sm float-right m-auto paging_ul'
              "
              :page-class="'page-link'"
              :prev-class="'page-link prev_paging'"
              :next-class="'page-link next_paging'"
            >
            </paginate>
          </div>
        </div>
      </div>
    </div>
    <template #modal-footer>
      <b-button
        variant="primary"
        @click="selectedHandler()"
        :disabled="disabledSelectedHandler()"
        class="style_btn"
        ><i class="fa fa-check"></i> ตกลง</b-button
      >
      <b-button variant="secondary" @click="close()"
        ><i class="fa fa-times"></i> ยกเลิก</b-button
      >
    </template>
  </b-modal>
</template>
<script>
import Config from "../../../util/config";
import $ from "jquery";
import Constants from "../../../util/constants";
export default {
  name: "order-view-dialog-add-prodcut",
  data() {
    return {
      criteria: {
        search: "",
        orderBy: "",
        ascDesc: "",
        subCat: "",
        storeId: "",
        subCatSelected: false,
        orderDate: "",
        deliveryDate: "",
        deliveryTime: "",
        tag_filters: []
      },
      items: [],
      paging: {
        page: 1,
        limit: Config.pagination.limit,
        pages: Config.pagination.pages,
        rowTotal: 0,
        pageTotal: 0
      },
      selected: []
    };
  },

  methods: {
    selectedHandler() {
      this.$emit("selected", this.selected);
      this.$bvModal.hide("dialog_order_add_product");
    },
    selectedProduct(value, obj, indx) {
      if (value) {
        this.setSelectedColumn(obj);
        $(`tr#checkbox_product${indx}`).attr("class", "highlight");
      } else {
        const index = this.selected.indexOf(obj);
        if (index > -1) {
          this.selected.splice(index, 1);
        }
        $(`tr#checkbox_product${indx}`).removeClass("highlight");
      }
    },
    setSelectedColumn(obj) {
      this.selected.push(obj);
    },
    close() {
      this.$bvModal.hide("dialog_order_add_product");
    },

    changeLimit() {
      this.getListItems(
        this.criteria.storeId,
        this.criteria.subCat,
        this.criteria.orderDate,
        this.criteria.deliveryDate,
        this.criteria.deliveryTime
      );
    },
    changePage(page) {
      this.paging.page = page;
      this.getListItems(
        this.criteria.storeId,
        this.criteria.subCat,
        this.criteria.orderDate,
        this.criteria.deliveryDate,
        this.criteria.deliveryTime
      );
    },
    sortHandler(orderBy) {
      if (orderBy != this.criteria.orderBy) {
        this.criteria.ascDesc = "asc";
      } else {
        this.criteria.ascDesc = this.criteria.ascDesc == "asc" ? "desc" : "asc";
      }
      this.criteria.orderBy = orderBy;
      this.getListItems(
        this.criteria.storeId,
        this.criteria.subCat,
        this.criteria.orderDate,
        this.criteria.deliveryDate,
        this.criteria.deliveryTime
      );
    },
    searchHandler() {
      this.paging.page = 1;
      this.getListItems(
        this.criteria.storeId,
        this.criteria.subCat,
        this.criteria.orderDate,
        this.criteria.deliveryDate,
        this.criteria.deliveryTime
      );
    },
    async getListItems(storeId, subCat, orderDate, deliveryDate, deliveryTime) {
      let tagFilters = [];
      this.criteria.tag_filters.map(v => {
        if (v.selected) {
          tagFilters.push(v.id);
        }
      });
      tagFilters = tagFilters.join(",");

      this.criteria.subCat = subCat;
      this.criteria.storeId = storeId;
      this.criteria.orderDate = orderDate ? orderDate : "";
      this.criteria.deliveryDate = deliveryDate ? deliveryDate : "";
      this.criteria.deliveryTime = deliveryTime ? deliveryTime : "";
      let subcatParam = this.criteria.subCatSelected ? "0" : "1";
      if (this.criteria.subCat == "0") {
        subcatParam = "0";
      }
      let params = `search=${this.criteria.search}&storeId=${storeId}&subCat=${subcatParam}&orderDate=${orderDate}&deliveryDate=${deliveryDate}&deliveryTime=${deliveryTime}`;
      params += `&page=${this.paging.page}&limit=${this.paging.limit}&orderBy=${this.criteria.orderBy}&ascDesc=${this.criteria.ascDesc}`;
      params += `&tag_filters=${tagFilters}`;
      const result = await this.HttpServices.getData(
        `/ab_order/getAllProducts?${params}`
      );
      if (result && result.status.code == "200") {
        const data = result.data;
        var total = Math.ceil(data.rowTotal / this.paging.limit);
        this.paging.rowTotal = data.rowTotal;
        this.paging.pageTotal = total;
        this.items = data.data;
        for (const v of this.items) {
          if (!v.imgs_encode) {
            v.imgs = Constants.noImageData;
          }
        }
      } else {
        this.paging.rowTotal = 0;
        this.items = [];
      }
    },
    async getFilterGroups() {
      const result = await this.HttpServices.getData(
        `/ab_product/getCountFilter`
      );
      this.criteria.tag_filters = result.data.tags;
    },
    disabledSelectedHandler() {
      return this.selected.length == 0;
    },
    initData() {
      this.selected = [];
    }
  }
};
</script>
<style scope>
.checkbox_style > .custom-control {
  left: 12px;
}
</style>
