<template>
  <div>
    <b-modal id="dialog_order_cost_product" size="lg">
      <template #modal-title>
        <h3> ต้นทุนราคาสินค้า</h3>
      </template>
      <div>
        <div class="mb-2 row">
          <label class="col-sm-4 col-form-label label_algin_right">ต้นทุนราคาสินค้า</label>
          <div class="col-sm-8">
            <base-input name="cost_product"
                        placeholder="ต้นทุนราคาสินค้า"
                        v-model="item.cost"
                        v-decimal-format>
            </base-input>
          </div>
        </div>
        <div class="mb-2 row">
         <label class="col-sm-4 col-form-label label_algin_right">รายละเอียดเพิ่มเติม</label>
          <div class="col-sm-8">
            <b-form-textarea name="cost_detail" placeholder="รายละเอียดเพิ่มเติม" v-model="item.remark" rows="3" max-rows="5"></b-form-textarea>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <b-button variant="primary" @click="clickAgree()" class="style_btn"><i class="fa fa-check"></i> ตกลง</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  name: 'order-view-dialog-cost-product-child',
  data () {
    return {
      item: {}
    }
  },
  methods: {
    productSelectedHandler(product){
      this.item = JSON.parse(JSON.stringify(product));
    },
    clickAgree () {
      this.$bvModal.hide(`dialog_order_cost_product`);
      this.$emit('selected', this.item);
    },
    close () {
      this.$bvModal.hide(`dialog_order_cost_product`);
    }
  }
}
</script>
