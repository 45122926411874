<template>
  <div>
    <b-modal id="dialog_customer_edit" size="lg">
      <template #modal-title>
        <h3><i class="fa fa-edit"></i> แก้ไขข้อมูลลูกค้า (ในฐานข้อมูล) {{customer.id}}</h3>
      </template>
        <div class="row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label">ชื่อลูกค้า</label>
                  <base-input name="name" placeholder="ชื่อ" v-model="customer.name" :rules="{required: true}"></base-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label">เบอร์โทรศัพท์</label>
                  <base-input name="phone" :rules="{required: true}" placeholder="เบอร์โทรศัพท์" v-model="customer.phone"></base-input>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">อีเมล</label>
                  <base-input type="email" name="email" placeholder="อีเมล" v-model="customer.email"></base-input>
                </div>
              </div>
              <div class="col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">ไลน์</label>
                  <base-input name="line" placeholder="ไลน์" v-model="customer.line"></base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="mb-4">
              <label class="form-label optional">ที่อยู่จัดส่ง</label>
              <b-form-textarea name="address" placeholder="ที่อยู่จัดส่ง" v-model="customer.address" rows="3" max-rows="5"></b-form-textarea>
              <base-input name="address" v-model="customer.address" class="custom-input-valid-datepicker"></base-input>
            </div>
          </div>

          <div class="col-12">
            <hr class="my-4">
          </div>
          <div class="col-sm-12">
            <h6 class="heading-small text-muted mb-4">ข้อมูลออกใบกำกับภาษี/ใบเสร็จรับเงิน</h6>
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">ชื่อ (Contact)</label>
                  <base-input name="contact" placeholder="ชื่อผู้ติดต่อ" v-model="customer.contact"></base-input>
                 
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">ชื่อบริษัทตามใบเสร็จรับเงิน</label>
                  <base-input name="company_name" placeholder="ชื่อบริษัทตามใบเสร็จรับเงิน" v-model="customer.company_name"></base-input>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">หมายเลขผู้เสียภาษี</label>
                  <base-input name="tax_id" placeholder="หมายเลขผู้เสียภาษี" :rules="{max: 15}" v-model="customer.tax_id" max="10" v-number-only></base-input>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="mb-4">
                    <label class="form-label optional">เบอร์โทรศัพท์</label>
                    <base-input name="billing_phone" :rules="{min: 10, max: 10}" placeholder="เบอร์โทรศัพท์" v-model="customer.billing_phone" min="10" max="10" v-number-only></base-input>
                  </div>
              </div>
              <div class="col-lg-8 col-md-6">
                <div class="mb-4">
                  <label class="form-label optional">ที่อยู่ตามใบกำกับภาษี/ใบเสร็จรับเงิน</label>
                  <b-form-textarea name="billing_address" placeholder="ที่อยู่ตามใบกำกับภาษี/ใบเสร็จรับเงิน" v-model="customer.billing_address" rows="3" max-rows="5"></b-form-textarea>
                  <base-input name="billing_address" v-model="customer.billing_address" class="custom-input-valid-datepicker"></base-input>
                </div>
              </div>
            </div>

          </div>



          <div class="col-12">
            <hr class="my-4">
          </div>

          <div class="col-12">
            <div class="mb-4">
              <label class="form-label optional">หมายเหตุ</label>
              <b-form-textarea name="address" placeholder="หมายเหตุ" v-model="customer.remark" rows="3" max-rows="5"></b-form-textarea>
            </div>
          </div>
          <div class="col-12">
            <hr class="my-4">
          </div>
        </div>
      <template #modal-footer>
        <b-button variant="primary" @click="save()" class="style_btn"><i class="fa fa-check"></i> ตกลง</b-button>
        <b-button variant="secondary" @click="close()"><i class="fa fa-times"></i> ยกเลิก</b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import $ from 'jquery';
import config from '../../../util/config';
import mock from '../mock/order';
import { funcs } from '../../../util/scripts';
import Constants from '../../../util/constants';
import Config from '../../../util/config';
export default {
  //name: 'order-dialog-edit-customer',
  data () {
    return {
      item: {
        id: '',
        name: '',
        contact: '',
        phone: '',
        line: '',
        email: '',
        billing_address: '',
        tax_id: '',
        address: '',
        created: '',
        updated: '',
        remark: '',
        credit_status: '',
        credit_line: '',
        customer_type: '',
        credit_status: '',
        credit_line: '',
        company_name: '',
        billing_phone: ''
      },
      id: ''
    }
  },
  props: ['customer'],
  methods: {
    close () {
      
        this.$bvModal.hide(`dialog_customer_edit`);
    },
    save () {
      this.$emit('update-customer',this.customer);
      this.$bvModal.hide(`dialog_customer_edit`);
    },
    mounted() {
    }
  }
}
</script>
<style scoped>
.thumbnail{position: relative;display: inline-block;}
.action-delete{
  position:absolute;
  right: 5px;
  top: 0px;
}
</style>
